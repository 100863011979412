<template>
  <div class="page-container">
    <!-- 日期显示区域 -->
    <div class="header-card">
      <div class="date-header">
        <h2 class="date-title">{{ currentDate }}</h2>
      </div>
    </div>

    <!-- 店铺数据区域 -->
    <div v-for="(store, storeIndex) in stores" :key="storeIndex" class="store-section">
      <div class="store-header-card">
        <div class="store-header">
          <h2 class="store-title">{{ store.storeName }}</h2>
        </div>
      </div>

      <!-- 类别数据展示 -->
      <div class="category-grid">
        <el-row :gutter="24">
          <el-col :span="8" v-for="(category, index) in store.categoryTotals" :key="index">
            <div class="statistic-card">
              <div class="category-name">{{ category.productCategory }}</div>
              <div class="income-amount">{{ category.incomeFee }}</div>
              <div class="income-label">预估实收</div>
              <!-- 移除竖线元素 -->
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { ElLoading, ElMessage } from 'element-plus'
import { API_URLS } from '@/config'

const API_BASE_URL = API_URLS.DSP.QUERY


const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

export default {
  name: 'DataDisplay',
  setup() {
    const stores = ref([]);
    const currentDate = ref('');
    
    const fetchData = async () => {
      const loadingInstance = ElLoading.service({
        fullscreen: true,
        text: '加载数据中...',
        background: 'rgba(249, 247, 247, 0.9)'
      });

      try {
        const [categoryResponse, dataResponse] = await Promise.all([
          fetch(API_BASE_URL + '/category_totals'),
          fetch(API_BASE_URL + '/get_data_json')
        ]);

        if (categoryResponse.ok && dataResponse.ok) {
          const categoryJson = await categoryResponse.json();
          const dataJson = await dataResponse.json();
          
          stores.value = categoryJson.map(store => ({
            storeName: store.storeName,
            categoryTotals: store.categoryTotals,
            data: dataJson.find(data => data.storeName === store.storeName).data,
            current_date: store.current_date
          }));
          currentDate.value = categoryJson[0].current_date;
        } else {
          ElMessage.error('数据加载失败');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        ElMessage.error('网络请求错误');
      } finally {
        loadingInstance.close();
      }
    }

    fetchData();
    
    return { 
      currentDate, 
      stores 
    }
  }
}
</script>

<style scoped>
.page-container {
  padding: 1rem;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background: linear-gradient(135deg, #f6f8fd 0%, #f1f4f9 100%);
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  box-sizing: border-box;
}

/* 日期卡片样式 */
.header-card {
  background: linear-gradient(135deg, #1a365d 0%, #2d4b94 100%);
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow: hidden;
}

.date-header {
  padding: 1.25rem;
  text-align: center;
  position: relative;
}

.date-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, 
    rgba(255,255,255,0) 0%, 
    rgba(255,255,255,0.3) 50%, 
    rgba(255,255,255,0) 100%
  );
}

.date-title {
  margin: 0;
  font-size: 1.25rem;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 1px;
  text-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* 店铺卡片样式 */
.store-section {
  animation: fadeInUp 0.5s ease forwards;
  opacity: 0;
  transform: translateY(20px);
}

.store-header-card {
  background: linear-gradient(135deg, #4a70c5 0%, #3f72af 100%);
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.1);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  overflow: hidden;
}

.store-header {
  padding: 1.25rem;
  text-align: center;
  position: relative;
}

.store-title {
  margin: 0;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.category-grid {
  padding: 0.5rem;
}

/* 统计卡片样式 */
.statistic-card {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 1.5rem;
  text-align: center;
  position: relative;
  margin-bottom: 1rem;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: all 0.3s ease;
  overflow: hidden;
}

.statistic-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 40px rgba(31, 38, 135, 0.15);
  border-color: rgba(74, 112, 197, 0.3);
}

/* 移除竖线样式 */

.category-name {
  font-size: 1.1rem;
  font-weight: 500;
  color: #1a365d;
  margin-bottom: 1rem;
}

.income-amount {
  font-size: 1.75rem;
  font-weight: 600;
  color: #4a70c5;
  margin-bottom: 0.5rem;
  transition: transform 0.3s ease;
}

.income-label {
  font-size: 0.875rem;
  color: #64748b;
}

/* 响应式布局 */
@media (max-width: 1600px) {
  .el-col {
    width: 33.33% !important;
  }
}

@media (max-width: 1200px) {
  .el-col {
    width: 50% !important;
  }
}

@media (max-width: 768px) {
  .page-container {
    padding: 1rem;
  }

  .el-col {
    width: 100% !important;
  }

  .statistic-card {
    margin-bottom: 1rem;
  }

  .income-amount {
    font-size: 1.5rem;
  }

  .category-name {
    font-size: 1rem;
  }
}

/* 大屏幕优化 */
@media (min-width: 1920px) {
  .date-title {
    font-size: 1.5rem;
  }
  
  .store-title {
    font-size: 1.4rem;
  }
  
  .category-name {
    font-size: 1.2rem;
  }
  
  .income-amount {
    font-size: 2rem;
  }
  
  .income-label {
    font-size: 1rem;
  }
}

/* 动画效果 */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.store-section {
  animation-delay: calc(var(--index) * 0.1s);
}

.statistic-card:hover .income-amount {
  transform: scale(1.05);
}

/* 加载动画 */
:deep(.el-loading-mask) {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
}

:deep(.el-loading-text) {
  color: #4a70c5;
  font-size: 1rem;
  margin-top: 1rem;
}

:deep(.el-loading-spinner .circular) {
  width: 42px;
  height: 42px;
}
</style>