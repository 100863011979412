<template>
  <div id="app">
    <el-container>
      <el-header>
        <el-menu 
          :default-active="activeIndex" 
          class="el-menu-demo" 
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">数据展示</el-menu-item>
          <el-menu-item index="2">订单查询</el-menu-item>
        </el-menu>
      </el-header>
      
      <el-main>
        <DataDisplay v-if="activeIndex === '1'" />
        <OrderInfo v-if="activeIndex === '2'" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { ref } from 'vue'
import DataDisplay from './components/DataDisplay.vue'
import OrderInfo from './components/OrderInfo.vue'

export default {
  name: 'App',
  components: {
    DataDisplay,
    OrderInfo
  },
  setup() {
    const activeIndex = ref('1')

    const handleSelect = (key) => {
      activeIndex.value = key
    }

    return {
      activeIndex,
      handleSelect
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.el-header {
  padding: 0;
}

.el-main {
  background-color: #f0f2f5;
  min-height: calc(100vh - 60px);
}
</style>