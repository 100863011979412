<template>
  <div class="order-info-container">
    <!-- 查询表单 -->
    <el-card class="query-form">
      <template #header>
        <div class="card-header">
          <span>销售数据查询</span>
        </div>
      </template>
      
      <el-form :model="queryForm" :label-width="formLabelWidth">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <el-form-item label="店铺">
              <el-select
                v-model="queryForm.member_id"
                placeholder="请选择店铺"
                style="width: 100%"
              >
                <el-option
                  v-for="(id, store) in memberIdMap"
                  :key="id"
                  :label="store"
                  :value="id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="时间范围">
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD HH:mm:ss"
                :default-time="[
                  new Date(2000, 1, 1, 0, 0, 0),
                  new Date(2000, 1, 1, 23, 59, 59),
                ]"
                style="width: 100%"
                :shortcuts="shortcuts"
                :disabledDate="disabledDate"
              />
            </el-form-item>
          </el-col>
          
          <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="4">
            <el-form-item class="submit-item">
              <el-button type="primary" @click="fetchData" :loading="loading" class="submit-button">
                查询
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <!-- 数据展示区域 -->
    <el-card v-if="hasData" class="data-display">
      <template #header>
        <div class="card-header">
          <span>查询结果</span>
        </div>
      </template>

      <!-- 汇总信息 -->
      <div class="summary-section">
        <el-descriptions 
          title="汇总数据" 
          :column="descriptionColumns" 
          :border="true"
          :size="descriptionSize"
        >
          <el-descriptions-item label="总订单数">
            {{ summary?.grand_total?.total_orders }}
          </el-descriptions-item>
          <el-descriptions-item label="总数量">
            {{ summary?.grand_total?.total_quantity }}
          </el-descriptions-item>
          <el-descriptions-item label="总金额">
            ¥{{ summary?.grand_total?.total_amount?.toFixed(2) }}
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <!-- 销售汇总表格 -->
      <div class="table-section">
        <h3>销售汇总</h3>
        <el-table 
          :data="summarizedData" 
          border 
          style="width: 100%" 
          class="responsive-table"
          :max-height="tableMaxHeight"
          :default-sort="{ prop: 'amount', order: 'descending' }"
        >
          <el-table-column 
            prop="product_name" 
            label="商品名称" 
            min-width="180"
            show-overflow-tooltip
          />
          <el-table-column 
            prop="total_quantity" 
            label="总数量" 
            min-width="100" 
            sortable
          />
          <el-table-column 
            prop="avg_unit_price" 
            label="平均单价" 
            min-width="100"
            sortable
          >
            <template #default="scope">
              ¥{{ scope.row.avg_unit_price.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column 
            prop="total_amount" 
            label="总金额" 
            min-width="100"
            sortable
          >
            <template #default="scope">
              ¥{{ scope.row.total_amount.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column 
            prop="order_count" 
            label="订单数" 
            min-width="90" 
            sortable
          />
          <el-table-column
            prop="percentage"
            label="占比"
            min-width="90"
            sortable
          >
            <template #default="scope">
              {{ scope.row.percentage.toFixed(2) }}%
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { ElMessage } from 'element-plus'
import { API_URLS } from '@/config'


export default {
  name: 'OrderInfo',
  setup() {
    const API_URL = API_URLS.SALES.QUERY
    const queryForm = ref({
      member_id: null
    })
    const dateRange = ref([])
    const loading = ref(false)
    const salesData = ref([])
    const summary = ref(null)

    // 会员ID映射表
    const memberIdMap = {
      '成都': 160164220,
      '上海': 155290087,
    }

    // 禁用日期
    const disabledDate = (time) => {
      return time.getTime() > Date.now()
    }

    // 日期快捷选项
    const shortcuts = [
      {
        text: '最近一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: '最近一个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: '最近三个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]

    // 响应式布局相关的变量
    const formLabelWidth = computed(() => {
      return window.innerWidth < 768 ? '90px' : '120px'
    })

    const descriptionColumns = computed(() => {
      if (window.innerWidth < 768) return 1
      if (window.innerWidth < 1200) return 2
      return 3
    })

    const descriptionSize = computed(() => {
      return window.innerWidth < 768 ? 'small' : 'default'
    })

    const tableMaxHeight = ref('400px')

    // 是否有数据
    const hasData = computed(() => salesData.value.length > 0)

    // 计算销售汇总数据
    const summarizedData = computed(() => {
      if (!salesData.value.length) return []
      
      // 按产品名称分组并汇总数据
      const groupedData = {}
      const totalAmount = summary.value?.grand_total?.total_amount || 0

      salesData.value.forEach(item => {
        if (!groupedData[item.product_name]) {
          groupedData[item.product_name] = {
            product_name: item.product_name,
            total_quantity: 0,
            total_amount: 0,
            order_count: new Set(),
            unit_prices: []
          }
        }
        
        const group = groupedData[item.product_name]
        group.total_quantity += item.quantity
        group.total_amount += item.amount
        group.order_count.add(item.order_id)
        group.unit_prices.push(item.unit_price)
      })

      // 转换为数组并计算额外数据
      return Object.values(groupedData).map(group => ({
        product_name: group.product_name,
        total_quantity: group.total_quantity,
        total_amount: group.total_amount,
        order_count: group.order_count.size,
        avg_unit_price: group.unit_prices.reduce((a, b) => a + b, 0) / group.unit_prices.length,
        percentage: (group.total_amount / totalAmount) * 100
      }))
    })

    // 获取数据
    const fetchData = async () => {
      if (!queryForm.value.member_id || !dateRange.value || dateRange.value.length !== 2) {
        ElMessage.warning('请填写完整的查询条件')
        return
      }

      loading.value = true
      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            member_id: queryForm.value.member_id,
            start_time: dateRange.value[0],
            end_time: dateRange.value[1],
            export_format: 'json'
          })
        })

        const data = await response.json()
        if (response.ok) {
          salesData.value = data.data
          summary.value = data.summary
          ElMessage.success('数据加载成功')
        } else {
          throw new Error(data.detail || '查询失败')
        }
      } catch (error) {
        ElMessage.error(error.message || '网络请求失败')
        salesData.value = []
        summary.value = null
      } finally {
        loading.value = false
      }
    }

    // 处理窗口大小变化
    const handleResize = () => {
      const windowHeight = window.innerHeight
      const offset = windowHeight <= 768 ? 300 : 350 // 调整表格最大高度
      tableMaxHeight.value = `${windowHeight - offset}px`
    }

    // 导出数据
    const exportData = async () => {
      if (!hasData.value) {
        ElMessage.warning('暂无数据可导出')
        return
      }

      loading.value = true
      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            member_id: queryForm.value.member_id,
            start_time: dateRange.value[0],
            end_time: dateRange.value[1],
            export_format: 'excel'
          })
        })

        if (!response.ok) {
          throw new Error('导出失败')
        }

        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = `销售数据_${new Date().getTime()}.xlsx`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
        
        ElMessage.success('导出成功')
      } catch (error) {
        ElMessage.error(error.message || '导出失败')
      } finally {
        loading.value = false
      }
    }

    // 重置表单
    const resetForm = () => {
      queryForm.value.member_id = null
      dateRange.value = []
      salesData.value = []
      summary.value = null
    }

    // 生命周期钩子
    onMounted(() => {
      handleResize()
      window.addEventListener('resize', handleResize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    return {
      queryForm,
      dateRange,
      loading,
      salesData,
      summary,
      hasData,
      memberIdMap,
      shortcuts,
      disabledDate,
      summarizedData,
      formLabelWidth,
      descriptionColumns,
      descriptionSize,
      tableMaxHeight,
      fetchData,
      exportData,
      resetForm
    }
  }
}
</script>
<style scoped>
.order-info-container {
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  padding: clamp(0.5rem, 2vw, 2rem);
  box-sizing: border-box;
}

.query-form {
  margin-bottom: clamp(1rem, 3vw, 2rem);
}

.data-display {
  margin-top: clamp(1rem, 3vw, 2rem);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: clamp(0.5rem, 2vw, 1rem) 0;
}

.summary-section {
  margin-bottom: clamp(1rem, 3vw, 2rem);
}

.table-section {
  margin-top: clamp(1rem, 3vw, 2rem);
}

.table-section h3 {
  margin-bottom: clamp(0.5rem, 2vw, 1rem);
  font-size: clamp(1rem, 2vw, 1.2rem);
}

/* 表单样式优化 */
:deep(.el-form-item__content) {
  justify-content: flex-start;
}

:deep(.el-form-item) {
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
}

/* 查询按钮响应式样式 */
.submit-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .submit-item {
    justify-content: center;
    margin-top: 1rem;
  }
  
  .submit-button {
    width: 100%;
    max-width: 200px;
  }
}

/* 表格响应式样式 */
.responsive-table {
  width: 100%;
  overflow-x: auto;
}

:deep(.el-table) {
  font-size: clamp(0.875rem, 1.5vw, 1rem);
}

/* 确保描述列表在小屏幕上正确显示 */
:deep(.el-descriptions) {
  padding: clamp(0.5rem, 2vw, 1rem);
}

:deep(.el-descriptions__body) {
  width: 100%;
}

/* 优化小屏幕上的日期选择器 */
:deep(.el-date-editor.el-input__wrapper) {
  width: 100% !important;
}

/* 优化表单在不同屏幕尺寸下的间距 */
:deep(.el-row) {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

:deep(.el-col) {
  padding-left: clamp(0.5rem, 1vw, 1rem);
  padding-right: clamp(0.5rem, 1vw, 1rem);
}

/* 确保选择器在所有屏幕尺寸下都能正确显示 */
:deep(.el-select) {
  width: 100%;
}

@media (max-width: 480px) {
  :deep(.el-form-item__label) {
    margin-bottom: 0.5rem;
  }
  
  :deep(.el-descriptions__cell) {
    padding: 0.5rem !important;
  }
}
</style>