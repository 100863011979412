const config = {
    development: {
      BASE_API: 'http://127.0.0.1:7667',
      API_TIMEOUT: 5000
    },
    production: {
      BASE_API: 'http://bake.sean.date:7667',  // 生产环境地址
      API_TIMEOUT: 10000
    }
  }
  
  // 根据环境变量选择配置
  const env = process.env.NODE_ENV || 'development'
  const currentConfig = config[env]
  
  export const BASE_API = currentConfig.BASE_API
  export const API_TIMEOUT = currentConfig.API_TIMEOUT
  
  // 也可以导出特定的API路径
  export const API_URLS = {
    SALES: {
      QUERY: `${BASE_API}/api/sales/query`
    },
    DSP: {
        QUERY: `${BASE_API}`
      }
    // 可以添加更多API路径
  }
  
  export default currentConfig